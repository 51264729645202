.styled-table {
    width: 80%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    color: white;
}

.styled-table thead tr {

    background-color: #ff5400;
    color: #ffffff;
    text-align: left;
    
}

.styled-table th,
.styled-table td {
    
    padding: 12px 15px;
  
}

.styled-table tbody tr {
    border-bottom: 1px solid #ff5400;
}



.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #ff5400;
}


.controle_pessoas{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 20px;
}