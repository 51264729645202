.py{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rad-label {
    display: flex;
    align-items: center;
  
    border-radius: 100px;
    padding: 14px 16px;
    margin: 20px 0;
  
    cursor: pointer;
    transition: .3s;
    width: 50%;
    margin-left: 25%;

    

  }
  
  .rad-label:hover,
  .rad-label:focus-within {
    background: hsla(0, 0%, 80%, .14);
  }
  
  .rad-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
  }
  
  .rad-design {
    width: 22px;
    height: 22px;
    border-radius: 100px;
  
    background: linear-gradient(to right bottom, hsl(154, 97%, 62%), hsl(225, 97%, 62%));
    position: relative;
  }
  
  .rad-design::before {
    content: '';
  
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
  
    background: hsl(0, 0%, 90%);
    transform: scale(1.1);
    transition: .3s;
  }
  
  .rad-input:checked+.rad-design::before {
    transform: scale(0);
  }
  
  .rad-text {
    color: #FAFAFA;
    margin-left: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 900;
  
    transition: .3s;
  }
  
  .rad-input:checked~.rad-text {
    color: hsl(0, 0%, 40%);
  }



  .tituloPergunta{
    color: #FAFAFA;
    text-align: center;
    margin-bottom: 64px;
    margin-top: 48px;
    margin-right: 24px;
    margin-left: 24px;
  }



  @media screen and (max-width:800px){
    .rad-label {
      display: flex;
      align-items: center;
    
      border-radius: 100px;
      padding: 14px 16px;
      margin: 20px 0;
    
      cursor: pointer;
      transition: .3s;
      width: 90%;
      margin-left: 5%;
  
      
  
    }
  }
