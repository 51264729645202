@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  width: 100%;
  background: scroll;
  background: url("../../Assets/imgs/bcc_cadastro.png") fixed;
  /* background: url("https://cdn.dribbble.com/users/41719/screenshots/14984869/media/61eec0fce648d360f42b088fc8b9de8f.jpg?compress=1&resize=1000x750&vertical=top") fixed; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_pilulas {
  position: fixed;
  top: 24px;
  left: 24px;
  width: 160px;
}

.stepper {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.MuiPaper-root {
  background: none !important;
  overflow: hidden !important;
}
.MuiPaper-root .MuiStep-root {
  color: #fafafa !important;
  padding: 0;
  margin: 0;
}
.MuiPaper-root .MuiStep-root span {
  color: #fafafa !important;
}
.MuiSvgIcon-root circle {
  color: #ff5400;
}

.MuiStepIcon-completed path {
  color: #ff5400;
}

.card-cadastro-novo {
  position: absolute;

  margin-top: 24px;
  margin-bottom: 24px;
  width: 50%;
  left: 25%;

  padding: 30px 35px;
  padding-top: 120px;
  background: rgba(0, 0, 0, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  -webkit-backdrop-filter: blur(8.1px);
}

.card-cadastro h1 {
  color: #FF5400;
  font-weight: 800;
  margin: 0;
  margin-top: 24px;
}
.card-cadastro p {
  color: #FAFAFA;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 24px;
}

.login-form {
  position: relative;
  left: 0;
  width: 100%;
  color: #fafafa;
}

.login-form label {
  margin-bottom: 8px;
  margin-top: 16px;
}

.form-field {
  position: relative;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 16px;
  background: #111111;
  color: #fafafa;
  font-size: 12pt;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  outline: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(1) brightness(1000%) sepia(0%) saturate(10000%) hue-rotate(0deg);
  }

.form-group label {
  color: #fafafa;
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-error {
  position: relative;
  width: 100%;
  color: #fafafa;
  font-size: 0.8em;
  margin-top: 8px;
  margin-left: 8px;
}

.form-field ::placeholder {
  color: #c4c4c4;
}

/* .button {
  width: 100%;
  padding: 16px 0px;
  border: none;
  border-radius: 6px;
  outline: none;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px;
  cursor: pointer;
  margin-top: 15px;
  background: #FF5400;
  color: #FAFAFA;
} */

.button {
  width: 100%;
  padding: 16px 0px;
  border: none;
  border-radius: 6px;
  outline: none;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px;
  cursor: pointer;
  margin-top: 32px;
  background: #ff5400;
  color: #fafafa;
  box-shadow: 0px 5px #444;
}

.button:hover {
  box-shadow: 0 2.5px #333;
  transform: translateY(4px);
}

.button:active {
  box-shadow: 0 0.5px #333;
  transform: translateY(5px);
}

.termos {
  position: relative;
  font-size: 12px;
  padding: 16px;
}

.termos .link_termos {
  color: #ff5400;
}

@media (max-width: 950px) {
  .logo_pilulas {
    position: relative;
    top: 0;
    left: 0;
    padding-bottom: 30px;
    width: 160px;
    z-index: 999999;
    margin: 0;
  }
  .card-cadastro-novo {
    position: absolute;
    left: 0;
    top: 0;
    width: 96%;
    left: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 35px;
    padding-top: 50px;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }

  .stepper {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 0px;
  }

  .termos {
    position: relative;
    font-size: 10px;
    padding: 4px;
    padding-top: 16px;
  }
}

@media (min-width: 950px) and (max-width: 1300px) {
  .logo_pilulas {
    width: 160px;
    z-index: 999999;
  }
  .card-cadastro-novo {
    position: absolute;

    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
    left: 10%;

    padding: 30px 35px;
    padding-top: 120px;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }
}
