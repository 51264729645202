.video_curso{
    position: relative;
    margin-left: 10%;
    width: 80%;
    height: 350px;
    
}



.videos_texto{
    width: 80%;
    margin-left: 10%;
    padding: 50px 0;
}


.material_apoio{
    position: relative;
    width: 80%;
    margin-left: 10%;
    margin-top: 62px;
    background-color: #FAFAFA;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.2s;
    color: #FAFAFA;

  outline: none;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;

}

.material_apoio:hover {
    background-color: #ff5400;
    color:#FAFAFA;
}
.material_apoio:hover a{
    color:#FAFAFA;
}

.material_apoio:active {
    background-color: #ff5400;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

.material_apoio a{
    font-size: 32px;
    font-weight: bold;
    color: #444444;
}



@media (max-width: 500px){
    
    .videos_texto{
       
        margin-top: 30px;
    }

    .material_apoio a{
        font-size: 20px;
        font-weight: bold;
        color: #444444;
    }
    
    
    
    
  
    
      
      }
      
      @media (min-width: 500px) and (max-width: 800px) {
        .videos_texto{
            
            margin-top: 30px;
        }
        
        
      
      }

