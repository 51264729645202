@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ephesis&display=swap');
@media screen and (min-width: 1100px){
    
    .pag_todo_lp{
        position: absolute;
        left: 0;
        height: auto;
        min-height: 100vh;
        padding-bottom: 50px;
        width: 100%;
        background-color: #fafafa;
        padding-top: 70px;
      }
    

    .supertitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size:25px;
        color: white;
    }

    

    .titulo{
        text-align: center;
        width: 100%;
        background: midnightblue;
        padding-top: 70px;
        padding-bottom: 30px;
        font-size:60px;
        color: white;
    }

    .subtitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size:27px;
        color: white;
    }

    .quadrado{
        position: relative;
        margin-left: 5%;
        top: -20px;
        width: 90%;
        /* height: 150px; */
        background: RoyalBlue;
        border-radius: 10px;
        



    }

    .quadrado p{
        position:relative;
        text-align: center;
        padding-top:15px;
        padding-left: 12%;
        padding-right: 12%;
        padding-bottom: 15px;
        font-size: 35px;
        color:white;
    }

    .imgselo img{
        position:absolute;
        width: 10%;
        top: 4%;
        left:2%;

    }

    .imgselo img:nth-child(2){
        position:absolute;
        width: 10%;
        top: 4%;
        left:88%;

    }
    

     

   /* INICIO DOS TEMAS */
   .container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2200px;
    flex-wrap: wrap;
    padding:40px 0;
  }

  .container .card{
      position: relative;
      width: 30%;
      height: 380px;
      box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
                    inset -5px -5px 5px rgba(255,255,255,0.5),
                     5px 5px 5px rgba(0,0,0,0.05),
                     -5px -5px 5px rgba(255,255,255,0.5);
      border-radius:15px;
      margin:20px;
  }

  .container .card .box{
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      background: #fffafa;
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      border-radius: 15px;
      display:flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
  }

  .container .card:hover .box{
      transform: translateY(-50px);
      box-shadow: 0 10px 40px rgba(0,0,0,0.2);
      background: linear-gradient(45deg,midnightblue, darkblue,blue,darkblue, midnightblue);
}

  .container .card .box .content{
      padding: 20px;
      text-align: center;
  }

  .container .card .box .content h2{
    position: absolute;
    top: -10px;
    right: 0px;
    font-size: 18em;
    color: rgba(20, 0, 73, 0.05);
    transition: 0.5s;
    pointer-events: none;
}

  .container .card:hover .box .content h2{
      color: rgba(0,0,0,0.15);

  }


  

  .container .card .box .content h3{
      font-size: 1.8em;
      color:#777;
      z-index: 1;
      transition:0.5s;
  }

  .container .card .box .content p{
      font-size: 1.5em;
      font-weight: 300;
      color:#777;
      z-index: 1;
      transition: 0.5s;
  }

  .container .card:hover .box .content h3,
  .container .card:hover .box .content p{
      color: #fff;
  }

  .container .card .box .content a{
      position: relative;
      display: inline-block;
      padding:8px 20px;
      background: #03a9f4;
      margin-top:15px;
      border-radius:20px;
      color: #fff;
      text-decoration: none;
      font-weight: 400;
      box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }

  .container .card .box .content a{
      /* background: #ff568f; */
      background: rgb(31, 172, 31);
  }

  .fraseImpacto{
      position: relative;
      width: 100%;
      background: midnightblue;
      /* margin-bottom: 50px; */
  }

  .fraseImpacto h2{
      text-align: center;
      font-size: 80px;
      color:white;
      padding-top: 50px;
      padding-bottom: 50px;
  }

  .fraseImpactoInscrever{
    position: relative;
    width: 100%;
    background: RoyalBlue;
    /* margin-bottom: 20px; */
}

.fraseImpactoInscrever h2{
    text-align: center;
    font-size: 40px;
    color:white;
    padding-top: 30px;
    padding-bottom: 30px;
}


  .inscrever{
    position: relative;
    width: 40%;
    margin-left: 28%;
    text-align: center;
    display: inline-block;
    padding:8px 20px;
    background: black;
    margin-top:15px;
    margin-bottom: 15px;
    border-radius:20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 50px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    transition: 0.5s;
}



.inscrever:hover{
    background: RoyalBlue;

}


.primeiras{
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    box-shadow: -10px -10px 15px rgba(255,255,255,1),
                        10px 10px 10px rgba(0,0,0,0.1),
                        inset -10px -10px 15px rgba(255,255,255,0.5),
                        inset 10px 10px 10px rgba(0,0,0,0.1);
}
   

.primeirasTexto{
    position: relative;
    top: 20px;
    /* left: 10%; */
    /* width:90%; */
    text-align:center;
    width: 80%;
    margin-left: 10%;
    top: 30%;
    font-size: 40px;
    


}


.primeirasTexto h2{
    font-family: 'Ephesis', cursive;
    font-size: 1.5em;
}

.textoLongo{
        margin-top: 100px;
        width: 100%;
        padding-top:50px;
        padding-bottom: 50px;
        box-shadow: -10px -10px 15px rgba(255,255,255,1),
                            10px 10px 10px rgba(0,0,0,0.1),
                            inset -10px -10px 15px rgba(255,255,255,0.5),
                            inset 10px 10px 10px rgba(0,0,0,0.1);
        text-align: center;
        font-size: 30px;
    
}


.bonus{
    width: 100%;
    background: midnightblue;
    text-align: left;
    color:white;
    padding-top: 50px;
    padding-bottom:50px;
    font-size: 30px;
    /* padding-left:5%; */
    margin-top:50px;
}

.bonus1, .bonus2, .bonus3{
    padding-left:5%;

}

.bonusTit{
    font-size:40px;
    text-align: center;
    padding-left:0%;
}

.fraseJogada{
    width: 100%;
    text-align: center;
    background:midnightblue;
    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
}



    .jogoTotal{
        margin-top: 50px;
        width: 100%;
        background:black;
    }

    .jogoTotal h2{
        color: white;
        text-align: center;
        padding-top: 30px;
        font-size:40px;
    }
    .jogo{
        position:relative;
        margin-left:15%;
        width: 70%;
        
    }

    .exclusivo{
        margin-top:50px;
        width: 100%;
        background: midnightblue;
        /* padding-bottom: 40px; */

    }

    .exclusivo h2{
        text-align: left;
        padding-top: 50px;
        padding-bottom: 30px;
        margin-left: 100px;
        font-size:30px;
        color: white;

    }

    .exclusivo ul{
        font-size: 20px;
        list-style:square;
        margin-left: 100px;
        margin-bottom: 50px;
        color: white;
        text-align:left;
    }

    .exclusivo ul li{
        padding:5px;

    }

    .exclusivo img{
        position: relative;
        width: 100%;
        margin-top: -550px;
        margin-bottom: -100px;
        margin-left: 0%;
    }

    .palestrante{
        position: relative;
        width: 100%;
        /* margin-top:50px; */
    }

    .fotoPales{
        position: relative;
        width: 100%;
        left:0%;
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }

    .circuloPalestra img{
        position: relative;
        width: 30%;
        left: 35%;
    }

    .textoPalestrante{
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 50px;

    }

    .textoPalestrante p{
        text-align:center;
        font-size: 25px;
    }

    .textoPalestrante h2{
        text-align:center;
        font-family: 'Ephesis', cursive;
        font-size: 3em;
    }


    

    

   
    .inscrever2{
        position: relative;
        width: 50%;
        margin-left: 0%;
        text-align: center;
        display: inline-block;
        padding:8px 20px;
        background: black;
        margin-top:80px;
        margin-bottom: 15px;
        border-radius:20px;
        color: #fff;
        text-decoration: none;
        font-weight: 400;
        font-size: 50px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        transition: 0.5s;
    }
    
    
    
    .inscrever2:hover{
        background: RoyalBlue;
    
    }

}

@media screen and (min-width: 820px) and (max-width: 1100px){
    
  
    

    .supertitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size:25px;
        color: white;
    }

    .titulo{
        text-align: center;
        width: 100%;
        background: midnightblue;
        padding-top: 70px;
        padding-bottom: 30px;
        font-size:60px;
        color: white;
    }

    .subtitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size:27px;
        color: white;
    }

    .quadrado{
        position: relative;
        margin-left: 5%;
        top: -20px;
        width: 90%;
        /* height: 150px; */
        background: RoyalBlue;
        border-radius: 10px;
        



    }

    .quadrado p{
        position:relative;
        text-align: center;
        padding-top:15px;
        padding-left: 12%;
        padding-right: 12%;
        padding-bottom: 15px;
        font-size: 30px;
        color:white;
    }

    .imgselo img{
        position:absolute;
        width: 10%;
        top: 4%;
        left:2%;

    }

    .imgselo img:nth-child(2){
        position:absolute;
        width: 10%;
        top: 4%;
        left:88%;

    }


    

     

   /* INICIO DOS TEMAS */
   .container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2200px;
    flex-wrap: wrap;
    padding:40px 0;
  }

  .container .card{
      position: relative;
      width: 30%;
      height: 380px;
      box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
                    inset -5px -5px 5px rgba(255,255,255,0.5),
                     5px 5px 5px rgba(0,0,0,0.05),
                     -5px -5px 5px rgba(255,255,255,0.5);
      border-radius:15px;
      margin:20px;
  }

  .container .card .box{
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      background: #fffafa;
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      border-radius: 15px;
      display:flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
  }

  .container .card:hover .box{
      transform: translateY(-50px);
      box-shadow: 0 10px 40px rgba(0,0,0,0.2);
      background: linear-gradient(45deg,midnightblue, darkblue,blue,darkblue, midnightblue);
}

  .container .card .box .content{
      padding: 20px;
      text-align: center;
  }

  .container .card .box .content h2{
    position: absolute;
    top: -10px;
    right: 0px;
    font-size: 18em;
    color: rgba(20, 0, 73, 0.05);
    transition: 0.5s;
    pointer-events: none;
}

.container .card:hover .box .content h2{
    color: rgba(0,0,0,0.15);

}

  .container .card .box .content h3{
      font-size: 1.8em;
      color:#777;
      z-index: 1;
      transition:0.5s;
  }

  .container .card .box .content p{
      font-size: 1.5em;
      font-weight: 300;
      color:#777;
      z-index: 1;
      transition: 0.5s;
  }

  .container .card:hover .box .content h3,
  .container .card:hover .box .content p{
      color: #fff;
  }

  .container .card .box .content a{
      position: relative;
      display: inline-block;
      padding:8px 20px;
      background: #03a9f4;
      margin-top:15px;
      border-radius:20px;
      color: #fff;
      text-decoration: none;
      font-weight: 400;
      box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }

  .container .card .box .content a{
      /* background: #ff568f; */
      background: rgb(31, 172, 31);
  }


  .fraseImpacto{
    position: relative;
    width: 100%;
    background: midnightblue;
    /* margin-bottom: 50px; */
}

.fraseImpacto h2{
    text-align: center;
    font-size: 80px;
    color:white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.fraseImpactoInscrever{
  position: relative;
  width: 100%;
  background: RoyalBlue;
  /* margin-bottom: 20px; */
}

.fraseImpactoInscrever h2{
  text-align: center;
  font-size: 40px;
  color:white;
  padding-top: 30px;
  padding-bottom: 30px;
}

  .inscrever{
    position: relative;
    width: 50%;
    margin-left: 25%;
    text-align: center;
    display: inline-block;
    padding:8px 20px;
    background: black;
    margin-top:15px;
    margin-bottom: 15px;
    border-radius:20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 50px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    transition: 0.5s;
}



.inscrever:hover{
    background: RoyalBlue;

}



.primeiras{
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    box-shadow: -10px -10px 15px rgba(255,255,255,1),
                        10px 10px 10px rgba(0,0,0,0.1),
                        inset -10px -10px 15px rgba(255,255,255,0.5),
                        inset 10px 10px 10px rgba(0,0,0,0.1);
}
   

.primeirasTexto{
    position: relative;
    top: 20px;
    /* left: 10%; */
    /* width:90%; */
    text-align:center;
    width: 80%;
    margin-left: 10%;
    top: 30%;
    font-size: 40px;
    


}


.primeirasTexto h2{
    font-family: 'Ephesis', cursive;
    font-size: 1.5em;
}

.textoLongo{
    margin-top: 100px;
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    box-shadow: -10px -10px 15px rgba(255,255,255,1),
                        10px 10px 10px rgba(0,0,0,0.1),
                        inset -10px -10px 15px rgba(255,255,255,0.5),
                        inset 10px 10px 10px rgba(0,0,0,0.1);
    text-align: center;
    font-size: 30px;

}

.bonus{
    width: 100%;
    background: midnightblue;
    text-align: left;
    color:white;
    padding-top: 50px;
    padding-bottom:50px;
    font-size: 30px;
    /* padding-left:5%; */
    margin-top:50px;
}

.bonus1, .bonus2, .bonus3{
    padding-left:5%;

}

.bonusTit{
    font-size:40px;
    text-align: center;
    padding-left:0%;
}

.fraseJogada{
    width: 100%;
    text-align: center;
    background:midnightblue;
    font-size: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
}


    .jogoTotal{
        margin-top: 50px;
        width: 100%;
        background:black;
    }

    .jogoTotal h2{
        color: white;
        text-align: center;
        padding-top: 30px;
        font-size:40px;
    }
    .jogo{
        position:relative;
        margin-left:15%;
        width: 70%;
        
    }

    .exclusivo{
        margin-top:50px;
        width: 100%;
        background: midnightblue;
        /* padding-bottom: 40px; */

    }

    .exclusivo h2{
        text-align: left;
        padding-top: 50px;
        padding-bottom: 30px;
        margin-left: 100px;
        font-size:30px;
        color: white;

    }

    .exclusivo ul{
        font-size: 20px;
        list-style:square;
        margin-left: 100px;
        margin-bottom: 50px;
        color: white;
        text-align:left;
    }

    .exclusivo ul li{
        padding:5px;

    }

    .exclusivo img{
        position: relative;
        width: 100%;
        margin-top: -550px;
        margin-bottom: -100px;
        margin-left: 0%;
    }

    .palestrante{
        position: relative;
        width: 100%;
        /* margin-top:50px; */
    }

    .fotoPales{
        position: relative;
        width: 100%;
        left:0%;
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }

    .circuloPalestra img{
        position: relative;
        width: 30%;
        left: 35%;
    }

    .textoPalestrante{
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 50px;

    }

    .textoPalestrante p{
        text-align:center;
        font-size: 25px;
    }

    .textoPalestrante h2{
        text-align:center;
        font-family: 'Ephesis', cursive;
        font-size: 3em;
    }

    .inscrever2{
        position: relative;
        width: 50%;
        margin-left: 0%;
        text-align: center;
        display: inline-block;
        padding:8px 20px;
        background: black;
        margin-top:150px;
        margin-bottom: 15px;
        border-radius:20px;
        color: #fff;
        text-decoration: none;
        font-weight: 400;
        font-size: 50px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        transition: 0.5s;
    }
    
    
    
    .inscrever2:hover{
        background: RoyalBlue;
    
    }

}

@media screen and (max-width: 820px){
    
  
    

    .supertitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size:20px;
        color: white;
    }

    .titulo{
        text-align: center;
        width: 100%;
        background: midnightblue;
        padding-top: 70px;
        padding-bottom: 30px;
        font-size:35px;
        color: white;
        /* padding: 10px; */
    }

    .subtitulo{
        text-align: center;
        width: 100%;
        background: black;
        padding-top: 40px;
        padding-bottom: 40px;
        font-size:15px;
        color: white;
        
    }

    .quadrado{
        position: relative;
        margin-left: 5%;
        top: -20px;
        width: 90%;
        /* height: 180px; */
        background: RoyalBlue;
        border-radius: 10px;
        



    }

    .quadrado p{
        position: relative;
        text-align: center;
        padding-top:15px;
        padding-left: 12%;
        padding-right: 12%;
        padding-bottom:15px;
        font-size: 25px;
        color:white;
    }

    .imgselo img{
        position:absolute;
        width: 20%;
        top: -5%;
        left:-5%;

    }

    .imgselo img:nth-child(2){
        position:absolute;
        width: 20%;
        top: -5%;
        left:85%;

    }
    

     

  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2200px;
    flex-wrap: wrap;
    padding:40px 0;
  }

  .container .card{
      position: relative;
      width: 90%;
      height: 360px;
      box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
                inset -5px -5px 5px rgba(255,255,255,0.5),
                5px 5px 5px rgba(0,0,0,0.05),
                -5px -5px 5px rgba(255,255,255,0.5);
      border-radius:15px;
      margin:3px;
  }

  .container .card .box{
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      background: #fffafa;
      box-shadow: 0 10px 20px rgba(0,0,0,0.1);
      border-radius: 15px;
      display:flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
  }

  .container .card:hover .box{
      transform: translateY(-50px);
      box-shadow: 0 10px 40px rgba(0,0,0,0.2);
      background: linear-gradient(45deg,midnightblue, darkblue,blue,darkblue, midnightblue);
  }

  .container .card .box .content{
      padding: 20px;
      text-align: center;
  }

  .container .card .box .content h2{
    position: absolute;
    top: -10px;
    right: 0px;
    font-size: 18em;
    color: rgba(20, 0, 73, 0.05);
    transition: 0.5s;
    pointer-events: none;
}

.container .card:hover .box .content h2{
    color: rgba(0,0,0,0.15);

}

  .container .card .box .content h3{
      font-size: 1.8em;
      color:#777;
      z-index: 1;
      transition:0.5s;
  }

  .container .card .box .content p{
      font-size: 1.5em;
      font-weight: 300;
      color:#777;
      z-index: 1;
      transition: 0.5s;
  }

  .container .card:hover .box .content h3,
  .container .card:hover .box .content p{
      color: #fff;
  }

  .container .card .box .content a{
      position: relative;
      display: inline-block;
      padding:8px 10px;
      background: #03a9f4;
      margin-top:15px;
      border-radius:20px;
      color: #fff;
      text-decoration: none;
      font-weight: 400;
      box-shadow: 0 10px 20px rgba(0,0,0,0.2);
  }

  .container .card .box .content a{
      background: rgb(31, 172, 31);
  }

  .fraseImpacto{
    position: relative;
    width: 100%;
    background: midnightblue;
    /* margin-bottom: 50px; */
}

.fraseImpacto h2{
    text-align: center;
    font-size: 45px;
    color:white;
    padding-top: 50px;
    padding-bottom: 50px;
}

.fraseImpactoInscrever{
  position: relative;
  width: 100%;
  background: RoyalBlue;
  /* margin-bottom: 20px; */
}

.fraseImpactoInscrever h2{
  text-align: center;
  font-size: 40px;
  color:white;
  padding-top: 30px;
  padding-bottom: 30px;
}




  .inscrever{
    position: relative;
    width: 80%;
    margin-left: 5%;
    text-align: center;
    display: inline-block;
    padding:8px 20px;
    background: black;
    margin-top:15px;
    margin-bottom: 15px;
    border-radius:20px;
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 50px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    transition: 0.5s;
}



.inscrever:hover{
    background: RoyalBlue;

}



.primeiras{
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    box-shadow: -10px -10px 15px rgba(255,255,255,1),
                        10px 10px 10px rgba(0,0,0,0.1),
                        inset -10px -10px 15px rgba(255,255,255,0.5),
                        inset 10px 10px 10px rgba(0,0,0,0.1);
}
   

.primeirasTexto{
    position: relative;
    top: 20px;
    /* left: 10%; */
    /* width:90%; */
    text-align:center;
    width: 80%;
    margin-left: 10%;
    top: 30%;
    font-size: 40px;
    


}


.primeirasTexto h2{
    font-family: 'Ephesis', cursive;
    font-size: 1.5em;
}

.textoLongo{
    margin-top: 100px;
    width: 100%;
    padding-top:50px;
    padding-bottom: 50px;
    box-shadow: -10px -10px 15px rgba(255,255,255,1),
                        10px 10px 10px rgba(0,0,0,0.1),
                        inset -10px -10px 15px rgba(255,255,255,0.5),
                        inset 10px 10px 10px rgba(0,0,0,0.1);
    text-align: center;
    font-size: 30px;

}

.bonus{
    width: 100%;
    background: midnightblue;
    text-align: center;
    color:white;
    padding-top: 50px;
    padding-bottom:50px;
    font-size: 20px;
    margin-top:50px;

}

.bonusTit{
    font-size:25px;
    text-align: center;
    padding-left:0%;
}

.fraseJogada{
    width: 100%;
    text-align: center;
    background:midnightblue;
    font-size: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    color: white;
}


    .jogoTotal{
        margin-top: 50px;
        width: 100%;
        background:black;
    }

    .jogoTotal h2{
        color: white;
        text-align: center;
        padding-top: 30px;
        font-size:20px;
    }
    .jogo{
        position:relative;
        left:0;
        margin-top: 50px;
        width: 100%;
        
    }

    .exclusivo{
        margin-top:50px;
        width: 100%;
        background: midnightblue;
        /* padding-bottom: 40px; */

    }

    .exclusivo h2{
        text-align: center;
        padding-top: 50px;
        padding-bottom: 30px;
        /* margin-left: 100px; */
        font-size:30px;
        color: white;

    }

    .exclusivo ul{
        font-size: 20px;
        list-style:square;
        margin-left: 30px;
        margin-bottom: 50px;
        color: white;
        text-align:left;
    }

    .exclusivo ul li{
        padding:2px;

    }

    .exclusivo img{
        position: relative;
        width: 100%;
        /* margin-top: -550px; */
        margin-bottom: -10px;
        top: -50px;
        margin-left: 0%;
    }

    .palestrante{
        position: relative;
        width: 100%;
        /* margin-top:50px; */
    }

    .fotoPales{
        position: relative;
        width: 100%;
        left:0%;
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    }

    .circuloPalestra img{
        position: relative;
        width: 80%;
        left: 10%;
    }

    .textoPalestrante{
        position: relative;
        width: 80%;
        left: 10%;
        margin-top: 50px;

    }

    .textoPalestrante p{
        text-align:center;
        font-size: 25px;
    }

    .textoPalestrante h2{
        text-align:center;
        font-family: 'Ephesis', cursive;
        font-size: 3em;
    }

    .inscrever2{
        position: relative;
        width: 80%;
        /* margin-left: 10%; */
        text-align: center;
        display: inline-block;
        padding:8px 20px;
        background: black;
        margin-top:150px;
        margin-bottom: 15px;
        border-radius:20px;
        color: #fff;
        text-decoration: none;
        font-weight: 400;
        font-size: 30px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        transition: 0.5s;
    }
    
    
    
    .inscrever2:hover{
        background: RoyalBlue;
    
    }

}

