.thumbnail {
    width: 100%;
    height: 40%;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  .thumbnail img{
    object-fit: cover;
  }
  
  .placeholder {
    width: 100%;
    height: 150px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 15px;
    color: #aaa;
    font-size: 14px;
  }
  