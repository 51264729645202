
.detalhe_imagem_home_curso{
  background: url("https://images8.alphacoders.com/987/987256.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  top: 70px;
  border-radius: 800px 0 0 0;

}

body{
  overflow-x: hidden;
}

.pag_todo_game{
  padding-bottom: 0px;
  background-color: #140717;
  padding-top: 70px;
  min-width: 100vw;
  
  min-height: 100vh;
}



  
  .main-timeline {
    padding:100px;
    margin-top: 0px;
    position: relative;
  }
  
  .main-timeline::before,
  .main-timeline::after {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #e7e7e7;
    border-radius: 50%;
    border: 10px solid #303334;
    transform: translatex(-50%);
    position: absolute;
    left: 50%;
    top: 65px;
    z-index: 2;
  }
  
  .main-timeline::after {
    top: auto;
    bottom: 95px;
  }
  
  .main-timeline .timeline {
    padding: 35px 0;
    margin-top: -30px;
    position: relative;
    z-index: 1;
  }
  
  .main-timeline .timeline::before,
  .main-timeline .timeline::after {
    content: "";
    height: 100%;
    width: 50%;
    border-radius: 110px 0 0 110px;
    border: 15px solid #46b2bc;
    border-right: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  
  .main-timeline .timeline::after {
    height: calc(100% - 30px);
    width: calc(50% - 12px);
    border-color: #65c7d0;
    left: 12px;
    top: 15px;
  }
  
  .main-timeline .timeline-content {
    display: inline-block;
  }
  
  .main-timeline .timeline-content:hover {
    text-decoration: none;
  }
  
  .main-timeline .timeline-year {
    color: #65c7d0;
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    transform: translatey(-50%);
    position: absolute;
    top: 50%;
    left: 10%;
  }
  
  .main-timeline .timeline-icon {
      color: #65c7d0;
      font-size: 70px;
      display: inline-block;
      transform: translateY(-50%);
      position: absolute;
      left: 34%;
      top: 50%;
  }
  
  .main-timeline .content {
    color: #909090;
    width: 50%;
    padding: 20px;
    display: inline-block;
    float: right;
  
  }
  
  .main-timeline .title {
    color: #65c7d0;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 5px 0;
  }
  
  .main-timeline .description {
    font-size: 16px;
    margin: 0;
  }
  
  .main-timeline .timeline:nth-child(even)::before {
    left: auto;
    right: 0;
    border-radius: 0 110px 110px 0;
    border: 15px solid red;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even)::after {
    left: auto;
    right: 12px;
    border-radius: 0 100px 100px 0;
    border: 15px solid green;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even) .content {
    float: left;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-year {
      left: auto;
      right: 10%;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: auto;
    right: 32%;
  }







 


  
  .main-timeline .timeline:nth-child(1)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(1)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(1) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(1) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(1) .title {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(2)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(2)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(2) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(2) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(2) .title {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(3)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(3)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(3) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(3) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(3) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(4)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(4)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(4) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(4) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(4) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(5)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(5) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5) .title {
    color: #0071BD;
  }


  .main-timeline .timeline:nth-child(6)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(6)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(6) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(6) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(6) .title {
    color: #65c7d0;


  }

  .main-timeline .timeline:nth-child(7)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(7)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(7) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(7) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(7) .title {
    color: #EA3C14;
  }

  .main-timeline .timeline:nth-child(8)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(8)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(8) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(8) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(8) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(9)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(9)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(9) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(9) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(9) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(10)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(10)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(10) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(10) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(10) .title {
    color: #0071BD;
  }


  /* ----------------------------------------------- */
  
  
  .main-timeline .timeline:nth-child(11)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(11)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(11) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(11) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(11) .title {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(12)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(12)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(12) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(12) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(12) .title {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(13)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(13)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(13) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(13) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(13) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(14)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(14)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(14) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(14) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(14) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(15)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(15)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(15) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(15) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(15) .title {
    color: #0071BD;
  }


  .main-timeline .timeline:nth-child(16)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(16)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(16) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(16) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(16) .title {
    color: #65c7d0;


  }

  .main-timeline .timeline:nth-child(17)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(17)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(17) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(17) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(17) .title {
    color: #EA3C14;
  }

  .main-timeline .timeline:nth-child(18)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(18)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(18) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(18) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(18) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(19)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(19)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(19) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(19) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(19) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(20)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(20)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(20) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(20) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(20) .title {
    color: #0071BD;
  }



  /* ----------------------------------------------------- */


  .main-timeline .timeline:nth-child(21)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(21)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(21) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(21) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(21) .title {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(22)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(22)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(22) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(22) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(22) .title {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(23)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(23)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(23) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(23) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(23) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(24)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(24)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(24) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(24) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(24) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(25)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(25)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(25) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(25) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(25) .title {
    color: #0071BD;
  }


  .main-timeline .timeline:nth-child(26)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(26)::after {
    border-color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(26) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(26) .timeline-year {
    color: #65c7d0;


  }
  
  .main-timeline .timeline:nth-child(26) .title {
    color: #65c7d0;


  }

  .main-timeline .timeline:nth-child(27)::before {
    border-color: #ea3c14;
  }
  
  .main-timeline .timeline:nth-child(27)::after {
    border-color: #EF5720;
  }
  
  .main-timeline .timeline:nth-child(27) .timeline-icon {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(27) .timeline-year {
    color: #EA3C14;
  }
  
  .main-timeline .timeline:nth-child(27) .title {
    color: #EA3C14;
  }

  .main-timeline .timeline:nth-child(28)::before {
    border-color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(28)::after {
    border-color: #6CAF29;
  }
  
  .main-timeline .timeline:nth-child(28) .timeline-icon
  {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(28) .timeline-year {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(28) .title {
    color: #8CC63E;
  }
  
  .main-timeline .timeline:nth-child(29)::before {
    border-color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(29)::after {
    border-color: #FBB03B;
  }
  
  .main-timeline .timeline:nth-child(29) .timeline-icon {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(29) .timeline-year {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(29) .title {
    color: #F99324;
  }
  
  .main-timeline .timeline:nth-child(30)::before {
    border-color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(30)::after {
    border-color: #0050A3;
  }
  
  .main-timeline .timeline:nth-child(30) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(30) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(30) .title {
    color: #0071BD;
  }


/* ------------------------------------------------ */


.main-timeline .timeline:nth-child(31)::before {
  border-color: #46b2bc;
}

.main-timeline .timeline:nth-child(31)::after {
  border-color: #65c7d0;


}

.main-timeline .timeline:nth-child(31) .timeline-icon {
  color: #65c7d0;
}

.main-timeline .timeline:nth-child(31) .timeline-year {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(31) .title {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(32)::before {
  border-color: #ea3c14;
}

.main-timeline .timeline:nth-child(32)::after {
  border-color: #EF5720;
}

.main-timeline .timeline:nth-child(32) .timeline-icon {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(32) .timeline-year {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(32) .title {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(33)::before {
  border-color: #8CC63E;
}

.main-timeline .timeline:nth-child(33)::after {
  border-color: #6CAF29;
}

.main-timeline .timeline:nth-child(33) .timeline-icon
{
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(33) .timeline-year {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(33) .title {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(34)::before {
  border-color: #F99324;
}

.main-timeline .timeline:nth-child(34)::after {
  border-color: #FBB03B;
}

.main-timeline .timeline:nth-child(34) .timeline-icon {
  color: #F99324;
}

.main-timeline .timeline:nth-child(34) .timeline-year {
  color: #F99324;
}

.main-timeline .timeline:nth-child(34) .title {
  color: #F99324;
}

.main-timeline .timeline:nth-child(35)::before {
  border-color: #0071BD;
}

.main-timeline .timeline:nth-child(35)::after {
  border-color: #0050A3;
}

.main-timeline .timeline:nth-child(35) .timeline-icon {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(35) .timeline-year {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(35) .title {
  color: #0071BD;
}


.main-timeline .timeline:nth-child(36)::before {
  border-color: #46b2bc;
}

.main-timeline .timeline:nth-child(36)::after {
  border-color: #65c7d0;


}

.main-timeline .timeline:nth-child(36) .timeline-icon {
  color: #65c7d0;
}

.main-timeline .timeline:nth-child(36) .timeline-year {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(36) .title {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(37)::before {
  border-color: #ea3c14;
}

.main-timeline .timeline:nth-child(37)::after {
  border-color: #EF5720;
}

.main-timeline .timeline:nth-child(37) .timeline-icon {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(37) .timeline-year {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(37) .title {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(38)::before {
  border-color: #8CC63E;
}

.main-timeline .timeline:nth-child(38)::after {
  border-color: #6CAF29;
}

.main-timeline .timeline:nth-child(38) .timeline-icon
{
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(38) .timeline-year {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(38) .title {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(39)::before {
  border-color: #F99324;
}

.main-timeline .timeline:nth-child(39)::after {
  border-color: #FBB03B;
}

.main-timeline .timeline:nth-child(39) .timeline-icon {
  color: #F99324;
}

.main-timeline .timeline:nth-child(39) .timeline-year {
  color: #F99324;
}

.main-timeline .timeline:nth-child(39) .title {
  color: #F99324;
}

.main-timeline .timeline:nth-child(40)::before {
  border-color: #0071BD;
}

.main-timeline .timeline:nth-child(40)::after {
  border-color: #0050A3;
}

.main-timeline .timeline:nth-child(40) .timeline-icon {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(40) .timeline-year {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(40) .title {
  color: #0071BD;
}



.main-timeline .timeline:nth-child(41)::before {
  border-color: #46b2bc;
}

.main-timeline .timeline:nth-child(41)::after {
  border-color: #65c7d0;


}

.main-timeline .timeline:nth-child(41) .timeline-icon {
  color: #65c7d0;
}

.main-timeline .timeline:nth-child(41) .timeline-year {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(41) .title {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(42)::before {
  border-color: #ea3c14;
}

.main-timeline .timeline:nth-child(42)::after {
  border-color: #EF5720;
}

.main-timeline .timeline:nth-child(42) .timeline-icon {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(42) .timeline-year {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(42) .title {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(43)::before {
  border-color: #8CC63E;
}

.main-timeline .timeline:nth-child(43)::after {
  border-color: #6CAF29;
}

.main-timeline .timeline:nth-child(43) .timeline-icon
{
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(43) .timeline-year {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(43) .title {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(44)::before {
  border-color: #F99324;
}

.main-timeline .timeline:nth-child(44)::after {
  border-color: #FBB03B;
}

.main-timeline .timeline:nth-child(44) .timeline-icon {
  color: #F99324;
}

.main-timeline .timeline:nth-child(44) .timeline-year {
  color: #F99324;
}

.main-timeline .timeline:nth-child(44) .title {
  color: #F99324;
}

.main-timeline .timeline:nth-child(45)::before {
  border-color: #0071BD;
}

.main-timeline .timeline:nth-child(45)::after {
  border-color: #0050A3;
}

.main-timeline .timeline:nth-child(45) .timeline-icon {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(45) .timeline-year {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(45) .title {
  color: #0071BD;
}







.main-timeline .timeline:nth-child(46)::before {
  border-color: #46b2bc;
}

.main-timeline .timeline:nth-child(46)::after {
  border-color: #65c7d0;


}

.main-timeline .timeline:nth-child(46) .timeline-icon {
  color: #65c7d0;
}

.main-timeline .timeline:nth-child(46) .timeline-year {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(46) .title {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(47)::before {
  border-color: #ea3c14;
}

.main-timeline .timeline:nth-child(47)::after {
  border-color: #EF5720;
}

.main-timeline .timeline:nth-child(47) .timeline-icon {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(47) .timeline-year {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(47) .title {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(48)::before {
  border-color: #8CC63E;
}

.main-timeline .timeline:nth-child(48)::after {
  border-color: #6CAF29;
}

.main-timeline .timeline:nth-child(48) .timeline-icon
{
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(48) .timeline-year {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(48) .title {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(49)::before {
  border-color: #F99324;
}

.main-timeline .timeline:nth-child(49)::after {
  border-color: #FBB03B;
}

.main-timeline .timeline:nth-child(49) .timeline-icon {
  color: #F99324;
}

.main-timeline .timeline:nth-child(49) .timeline-year {
  color: #F99324;
}

.main-timeline .timeline:nth-child(49) .title {
  color: #F99324;
}

.main-timeline .timeline:nth-child(50)::before {
  border-color: #0071BD;
}

.main-timeline .timeline:nth-child(50)::after {
  border-color: #0050A3;
}

.main-timeline .timeline:nth-child(50) .timeline-icon {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(50) .timeline-year {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(50) .title {
  color: #0071BD;
}


.main-timeline .timeline:nth-child(51)::before {
  border-color: #46b2bc;
}

.main-timeline .timeline:nth-child(51)::after {
  border-color: #65c7d0;


}

.main-timeline .timeline:nth-child(51) .timeline-icon {
  color: #65c7d0;
}

.main-timeline .timeline:nth-child(51) .timeline-year {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(51) .title {
  color: #65c7d0;


}

.main-timeline .timeline:nth-child(52)::before {
  border-color: #ea3c14;
}

.main-timeline .timeline:nth-child(52)::after {
  border-color: #EF5720;
}

.main-timeline .timeline:nth-child(52) .timeline-icon {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(52) .timeline-year {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(52) .title {
  color: #EA3C14;
}

.main-timeline .timeline:nth-child(53)::before {
  border-color: #8CC63E;
}

.main-timeline .timeline:nth-child(53)::after {
  border-color: #6CAF29;
}

.main-timeline .timeline:nth-child(53) .timeline-icon
{
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(53) .timeline-year {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(53) .title {
  color: #8CC63E;
}

.main-timeline .timeline:nth-child(54)::before {
  border-color: #F99324;
}

.main-timeline .timeline:nth-child(54)::after {
  border-color: #FBB03B;
}

.main-timeline .timeline:nth-child(54) .timeline-icon {
  color: #F99324;
}

.main-timeline .timeline:nth-child(54) .timeline-year {
  color: #F99324;
}

.main-timeline .timeline:nth-child(54) .title {
  color: #F99324;
}

.main-timeline .timeline:nth-child(55)::before {
  border-color: #0071BD;
}

.main-timeline .timeline:nth-child(55)::after {
  border-color: #0050A3;
}

.main-timeline .timeline:nth-child(55) .timeline-icon {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(55) .timeline-year {
  color: #0071BD;
}

.main-timeline .timeline:nth-child(55) .title {
  color: #0071BD;
}






  /* ------------------------------------------  */


  /* OFF VERSION */

  .main-timeline {
    padding:100px;
    position: relative;
  }
  
  .main-timeline::before,
  .main-timeline::after {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #e7e7e7;
    border-radius: 50%;
    border: 10px solid #303334;
    transform: translatex(-50%);
    position: absolute;
    left: 50%;
    top: 65px;
    z-index: 2;
  }
  
  .main-timeline::after {
    top: auto;
    bottom: 95px;
  }
  
  .main-timeline .timeline-off {
    padding: 35px 0;
    margin-top: -30px;
    position: relative;
    z-index: 1;
  }
  
  .main-timeline .timeline-off::before,
  .main-timeline .timeline-off::after {
    content: "";
    height: 100%;
    width: 50%;
    border-radius: 110px 0 0 110px;
    border: 15px solid gray;
    border-right: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  
  .main-timeline .timeline-off::after {
    height: calc(100% - 30px);
    width: calc(50% - 12px);
    border-color: gray;
    left: 12px;
    top: 15px;
  }
  
  .main-timeline .timeline-content-off {
    display: inline-block;
  }
  
  .main-timeline .timeline-content-off:hover {
    text-decoration: none;
  }
  
  .main-timeline .timeline-year-off {
    color: gray;
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    transform: translatey(-50%);
    position: absolute;
    top: 50%;
    left: 10%;
  }
  
  .main-timeline .timeline-icon-off {
      color: gray;
      font-size: 70px;
      display: inline-block;
      transform: translateY(-50%);
      position: absolute;
      left: 34%;
      top: 50%;
  }
  
  .main-timeline .content-off {
    color: #909090;
    width: 50%;
    padding: 20px;
    display: inline-block;
    float: right;
  }
  
  .main-timeline .title-off {
    color: #65c7d0;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 5px 0;
  }
  
  .main-timeline .description-off {
    font-size: 16px;
    margin: 0;
  }
  
  .main-timeline .timeline-off:nth-child(even)::before {
    left: auto;
    right: 0;
    border-radius: 0 110px 110px 0;
    border: 15px solid gray;
    border-left: none;
  }
  
  .main-timeline .timeline-off:nth-child(even)::after {
    left: auto;
    right: 12px;
    border-radius: 0 100px 100px 0;
    border: 15px solid gray;
    border-left: none;
  }
  
  .main-timeline .timeline-off:nth-child(even) .content-off {
    float: left;
  }
  
  .main-timeline .timeline-off:nth-child(even) .timeline-year-off {
      left: auto;
      right: 10%;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-icon-off {
    left: auto;
    right: 32%;
  }

  .main-timeline .timeline-off::before {
    border-color: gray;
  }
  
  .main-timeline .timeline-off::after {
    border-color: gray;


  }
  
  .main-timeline .timeline-off .timeline-icon-off {
    color: gray;
  }
  
  .main-timeline .timeline-off .timeline-year-off {
    color: gray;


  }
  
  .main-timeline .timeline-off .title-off {
    color: gray;


  }

 

  
  @media screen and (max-width:1200px){
      .main-timeline .timeline:after{ border-radius: 88px 0 0 88px; }
      .main-timeline .timeline:nth-child(even):after{ border-radius: 0 88px 88px 0; }
      /* PARTE OFF */
      .main-timeline .timeline-off:after{ border-radius: 88px 0 0 88px; }
      .main-timeline .timeline-off:nth-child(even):after{ border-radius: 0 88px 88px 0; }
    }
  @media screen and (max-width:767px){
      .main-timeline .timeline{ margin-top: -19px; }
      .main-timeline .timeline:before {
          border-radius: 50px 0 0 50px;
          border-width: 10px;
      }
      .main-timeline .timeline:after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 43px 0 0 43px;
          border-width:10px;
          top: 9px;
          left: 9px;
      }
      .main-timeline .timeline:nth-child(even):before {
          border-radius: 0 50px 50px 0;
          border-width: 10px;
      }
      .main-timeline .timeline:nth-child(even):after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 0 43px 43px 0;
          border-width: 10px;
          top: 9px;
          right: 9px;
      }
      .main-timeline .timeline-icon{ font-size: 60px; }
      .main-timeline .timeline-year{ font-size: 40px; }



      /* PARTE OFF */


      .main-timeline .timeline-off{ margin-top: -19px; }
      .main-timeline .timeline-off:before {
          border-radius: 50px 0 0 50px;
          border-width: 10px;
      }
      .main-timeline .timeline-off:after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 43px 0 0 43px;
          border-width:10px;
          top: 9px;
          left: 9px;
      }
      .main-timeline .timeline-off:nth-child(even):before {
          border-radius: 0 50px 50px 0;
          border-width: 10px;
      }
      .main-timeline .timeline-off:nth-child(even):after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 0 43px 43px 0;
          border-width: 10px;
          top: 9px;
          right: 9px;
      }
      .main-timeline .timeline-icon-off{ font-size: 60px; }
      .main-timeline .timeline-year-off{ font-size: 40px; }
  }
  @media screen and (max-width:479px){
      .main-timeline .timeline-icon{
          font-size: 50px;
          transform:translateY(0);
          top: 25%;
          left: 10%;
      }
      .main-timeline .timeline-year{
          font-size: 25px;
          transform:translateY(0px);
          top: 65%;
          left: 9%;
      }
      .main-timeline .content{
          width: 90%;
          padding: 10px;
      }
      .main-timeline .title{ font-size: 18px; }
      .main-timeline .timeline:nth-child(even) .timeline-icon{
          right: 10%;
      }
      .main-timeline .timeline:nth-child(even) .timeline-year{
          right: 9%;
      }

      .main-timeline .timeline-year{
        display: none;
      }



      /* PARTE OFF */

      .main-timeline .timeline-icon-off{
        font-size: 50px;
        transform:translateY(0);
        top: 25%;
        left: 10%;
    }
    .main-timeline .timeline-year-off{
        font-size: 25px;
        transform:translateY(0px);
        top: 65%;
        left: 9%;
    }
    .main-timeline .content-off{
        width: 90%;
        padding: 10px;
    }
    .main-timeline .title-off{ font-size: 18px; }
    .main-timeline .timeline-off:nth-child(even) .timeline-icon-off{
        right: 10%;
    }
    .main-timeline .timeline-off:nth-child(even) .timeline-year-off{
        right: 9%;
    }

    .main-timeline .timeline-year-off{
      display: none;
    }
  }


  @media (max-width: 500px) {
    .main-timeline {
        padding:10px;
        padding-top: 100px;
        padding-bottom: 100px;
      }

   
      
  }