.logo_pilulas_login {
  position: relative;
  width: 50%;
  left: 25%;
  margin-bottom: 40px;
}

.form-group-2{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;
 

}

.card-login {
  position: absolute;
  bottom: 0;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 50%;
  left: 25%;

  padding: 30px 35px;
  background: rgba(0, 0, 0, 0.64);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  -webkit-backdrop-filter: blur(8.1px);
}

.card-login h1 {
  color: #91b7d9;
  font-weight: 800;
  margin: 0;
}

@media (max-width: 500px) {
  .logo_pilulas_login {
    position: relative;
    width: 80%;
    left: 10%;
    margin-bottom: 40px;
  }
  .card-login {
    position: absolute;

    margin-top: 24px;
    margin-bottom: 24px;
    width: 90%;
    left: 5%;

    padding: 30px 35px;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }
}

@media (min-width: 500px) and (max-width: 950px) {
  .logo_pilulas_login {
    position: relative;
    width: 50%;
    left: 25%;
    margin-bottom: 40px;
  }
  .card-login {
    position: absolute;

    margin-top: 24px;
    margin-bottom: 24px;
    width: 80%;
    left: 10%;

    padding: 30px 35px;
    background: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }
}

@media (min-width: 950px) and (max-width: 1300px) {
  .logo_pilulas_login {
    position: relative;
    width: 50%;
    left: 25%;
    margin-bottom: 40px;
  }
  .card-login {
    position: absolute;

    margin-top: 24px;
    margin-bottom: 24px;
    width: 60%;
    left: 20%;

    padding: 30px 35px;

    background: rgba(0, 0, 0, 0.64);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.1px);
    -webkit-backdrop-filter: blur(8.1px);
  }
}
