header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    background-color: #140717f8;
    transition: all ease 0.5s;
}

header.black {
    background-color: #141414;
}

.header--logo {
    height: 35px;
}

.header--logo img {
    height: 100%;
}

.direita--header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    height: 100%;
}

.header--user {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.direita--header p {
    color: #FAFAFA;
    margin-bottom: 0;
}

.header--user img {
    height: 100%;
    border-radius: 3px;
}

.logoutIcon {
    color: #FAFAFA;
    margin-left: 8px;
    cursor: pointer;
}

/* Estilo para o menu hambúrguer */
.hamburger-menu {
    display: block; /* Sempre visível em todas as resoluções */
    cursor: pointer;
    color: #FAFAFA;
    margin: 6px 0;
    transition: 0.4s;
}

/* Menu flutuante que aparece para baixo */
.menu-lateral {
    position: absolute;
    top: 70px; /* Ajusta a posição do menu abaixo do header */
    right: 0px; /* Alinha com o header */
    background-color: #140717f8; /* Ajuste de cor de fundo */
    color: #fff;
    display: none; /* Inicialmente escondido */
    flex-direction: column;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 998;
}

/* Quando o menu está aberto */
.menu-lateral.open {
    display: flex; /* Torna o menu visível quando aberto */
}

/* Links dentro do menu */
.menu-lateral a {
    color: #fff;
    text-decoration: none;
    margin: 10px 0;
    font-size: 18px;
    transition: 0.3s;
    cursor: pointer;
}

.menu-lateral .item-menu:hover {
    border-bottom: 1px solid #ff5400;
}


.item-menu{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    padding: 2px 8px;
    cursor: pointer;
}

.item-menu p, .item-menu Link{
    color: #fff;
    text-decoration: none;
    margin: 10px 0;
    font-size: 18px;
    transition: 0.3s;
    cursor: pointer;

}
